import StyledMenuBottom from './styles'

import Row from '../Row'
import Text from '../Text'
import Button from '../Button'
import { setToken } from '../../global/Token'
import { useNavigate } from 'react-router-dom'
import Column from '../Column'

import IconHome from '../../assets/icons/home.svg'
import IconAnalyse from '../../assets/icons/analyse.svg'
import IconOperations from '../../assets/icons/operations.svg'
import IconVideo from '../../assets/icons/video.svg'
import Image from '../Image'
import { useEffect, useState } from 'react'

export default function MenuBottom(){

	const naviagate = useNavigate()
	const [pathname, setPathname] = useState('/')

	useEffect(() => {

		setPathname(location.pathname)

	}, [])

	function underline(pathnameParam: string){

		if(pathnameParam == '/operations' && pathname.includes(pathnameParam)) return '2px solid white'

		if(pathname == pathnameParam) return '2px solid white'

		return 'none'

	}

	return (
		<StyledMenuBottom
			// style={{ width: '100vw' }}
		>
			<Column
				onclick={() => naviagate('/')}
				extraStyles={{ borderBottom: underline('/') }}
			>
				<Image
					src={IconHome}
					extraStyles={{ cursor: 'pointer' }}
				/>
				<Text extraStyles={{ cursor: 'pointer' }}>
					Inicio
				</Text>
			</Column>
			<Column
				onclick={() => naviagate('/portfolio')}
				extraStyles={{ borderBottom: underline('/portfolio') }}
			>
				<Image
					src={IconAnalyse}
					extraStyles={{ cursor: 'pointer' }}
				/>
				<Text extraStyles={{ cursor: 'pointer' }}>
					Portfólio
				</Text>
			</Column>
			<Column
				onclick={() => naviagate('/operations')}
				extraStyles={{ borderBottom: underline('/operations') }}
			>
				<Image
					src={IconOperations}
					extraStyles={{ cursor: 'pointer' }}
				/>
				<Text extraStyles={{ cursor: 'pointer' }}>
					Sala de operações
				</Text>
			</Column>
			<Column
				onclick={() => naviagate('/academy')}
				extraStyles={{ borderBottom: underline('/academy') }}
			>
				<Image
					src={IconVideo}
					extraStyles={{ cursor: 'pointer' }}
				/>
				<Text extraStyles={{ cursor: 'pointer' }}>
					Aulas
				</Text>
			</Column>
		</StyledMenuBottom>
	)

}
