import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { Response } from '../../global/Api'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import ImageBgTesla from '../../assets/img/logo-tesla.svg'
import ImageBgFacebook from '../../assets/img/background-facebook.png'
import ImageBgBH from '../../assets/img/background-bh.png'
import ImageBgAmazon from '../../assets/img/logo-amazon.svg'
import ImageBgGoogle from '../../assets/img/background-google.png'
import ImageBgPaypal from '../../assets/img/background-paypal.png'

import socketio, { Socket } from 'socket.io-client'

import IconLive from '../../assets/icons/live.svg'
import IconEUR from '../../assets/icons/eur.svg'
import IconJPY from '../../assets/icons/jpy.svg'

export default function Operation(){

	const gameOptions = [
		{
			id: 'tesla',
			title: 'Tesla',
			description: 'Operadores do Elon Musk',
			url: ImageBgTesla,
			buttonNavigation: '/game/tesla',
			// active: 'EURJPY',
			active: 'ETHUSD',
		},
		{
			id: 'facebook',
			title: 'Facebook',
			description: 'Operadores de Mark Zuckerberg',
			url: ImageBgFacebook,
			buttonNavigation: '/game/facebook',
			// active: 'EURUSD',
			active: 'BTCUSD',
		},
		{
			id: 'bh',
			title: 'Berkshire hattaway',
			description: 'Operadores de Warren Buffet',
			url: ImageBgBH,
			buttonNavigation: '/game/tesla',
			// active: 'GBPUSD',
			active: 'BTCUSD',
		},
		{
			id: 'amazon',
			title: 'Amazon',
			description: 'Operadores de Jeff Bezos',
			url: ImageBgAmazon,
			buttonNavigation: '/game/tesla',
			// active: 'EURCAD',
			active: 'BCHUSD',
		},
		{
			id: 'google',
			title: 'Google',
			description: 'Operadores de Larry Page',
			url: ImageBgGoogle,
			buttonNavigation: '/game/google',
			// active: 'USDJPY',
			active: 'LTCUSD',
		},
		{
			id: 'paypal',
			title: 'PayPal',
			description: 'Operadores de Peter Thiel',
			url: ImageBgPaypal,
			buttonNavigation: '/game/paypal',
			// active: 'EURAUD',
			active: 'XRPUSD',
		},
	]

	const [view, setView] = useState('waiting' as 'waiting' | 'bet' | 'result')

	const [popup, setPopup] = useState({} as Response)
	const [market, setMarket] = useState({} as typeof gameOptions[0])

	const [socket, setSocket] = useState(null as null | Socket)
	const [socketData, setSocketData] = useState(null as any)

	useEffect(() => {

		setSocket(socketio(process.env.REACT_APP_SOCKET_URL || ''))

		const marketPath = location.pathname.replace('/operations/', '')

		const opt = gameOptions.find((opt) => opt.id == marketPath)

		setMarket(opt || gameOptions[0])

	}, [])

	useEffect(() => {

		if(socket != null){

			socket.on('ob_historical', (ev: any) => {

				console.log({ SocketHistorical: ev[market.active] })
				handleSocketData(ev[market.active])

			})

		}

	}, [socket])

	function handleSocketData(data: { status: string, action: string, active: string, minuteType: string, time: string, resultType: string, greens: number, reds: number }){

		console.log({ data })

		if(data == null){

			setView('waiting')
			setSocketData(['', ''])

		}

		try{

			if(data.status == 'CONFIRMADO'){

				setView('bet')
				setSocketData([
					data.action,
					data.time,
				])

			}else if(data.status == 'PENDENTE'){

				setView('waiting')
				setSocketData(['', ''])

			}else if(data.status == 'FINALIZADO'){

				setSocketData([
					data.resultType,
					''
				])

				setView('result')

				setTimeout(() => setView('waiting'), 1000)

			}
			else{

				setView('waiting')
				setSocketData(['', ''])

			}

		}catch{

			setView('waiting')
			setSocketData(['', ''])

		}

	}

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Row
				extraStyles={{
					alignItems: 'center',
					margin: `${styleguide.space.small} 0`
				}}
			>
				<Image
					src={market.url}
					extraStyles={{
						minWidth: 'unset',
						height: '30px',
						width: 'auto',

						marginRight: styleguide.space.small,
					}}
				/>
				<Text
					textoption='title'
					extraStyles={{
						margin: `${styleguide.space.small} 0`
					}}
				>
					{ market.title }
				</Text>
			</Row>

			<Column
				extraStyles={{
					padding: styleguide.space.small,
					width: '95%',

					borderRadius: styleguide.borderRadius.base,
					border: `1px solid ${styleguide.colors.separator}`
				}}
			>
				<Row>
					<Text
						textoption='subtitle'
						extraStyles={{
							color: '#5CED7B',
							fontWeight: '700',
						}}
					>
						Ao Vivo
					</Text>
					<Image
						src={IconLive}
						extraStyles={{
							minWidth: 'unset',
							width: '16px',
							height: 'auto',
							marginLeft: styleguide.space.small,
						}}
					/>
				</Row>
				<Column
					extraStyles={{
						background: styleguide.colors.mainBackGradient,
						width: '95%',
						padding: styleguide.space.small,
						margin: `${styleguide.space.small} 0`,

						borderRadius: styleguide.borderRadius.base,
						border: `1px solid ${styleguide.colors.separator}`,

						alignItems: 'stretch',
					}}
				>
					<Text>
						Ativo:
					</Text>
					<Row>

						<Image
							src={IconEUR}
							extraStyles={{
								minWidth: 'unset',
								width: '25px',
								height: 'auto',
							}}
						/>
						<Image
							src={IconJPY}
							extraStyles={{
								minWidth: 'unset',
								width: '25px',
								height: 'auto',
							}}
						/>
						<Text
							textoption='subtitle'
							extraStyles={{
								marginLeft: styleguide.space.small,
							}}
						>
							{ market.active }
						</Text>
					</Row>
					{
						view == 'bet'
							? <Row
							>
								<Column
									extraStyles={{
										minWidth: 'unset',
										width: 'calc(45% - 10px)',
										margin: '5px',
										padding: styleguide.space.small,

										borderRadius: styleguide.borderRadius.small,
										border: `1px solid ${styleguide.colors.separator}`
									}}
								>
									<Text>Ação</Text>
									<Text
										extraStyles={{
											backgroundColor: socketData[0] == 'Comprar' ? 'green' : 'red',
											padding: `0 ${styleguide.space.small}`,
											borderRadius: '3px',
										}}
									>
										{ socketData[0] }
									</Text>
								</Column>
								<Column
									extraStyles={{
										minWidth: 'unset',
										width: 'calc(45% - 10px)',
										margin: '5px',
										padding: styleguide.space.small,

										borderRadius: styleguide.borderRadius.small,
										border: `1px solid ${styleguide.colors.separator}`
									}}
								>
									<Text>Horário</Text>
									<Text
										extraStyles={{
											opacity: '0.7'
										}}
									>
										{ socketData[1] }
									</Text>
								</Column>
								<Column
									extraStyles={{
										minWidth: 'unset',
										width: 'calc(45% - 10px)',
										margin: '5px',
										padding: styleguide.space.small,

										borderRadius: styleguide.borderRadius.small,
										border: `1px solid ${styleguide.colors.separator}`
									}}
								>
									<Text>Tempo</Text>
									<Text
										extraStyles={{
											opacity: '0.7'
										}}
									>
										1 Min
									</Text>
								</Column>
							</Row>
							: <Text
								textoption='subtitle'
								extraStyles={{
									margin: styleguide.space.medium
								}}
							>
								{ view == 'result' ? socketData[0] : `Carregando nova entrada dos analistas d${['Facebook', 'Google', 'Paypal'].includes(market.title) ? 'o' : 'a'} ${market.title}...` }
							</Text>
					}
				</Column>
			</Column>

			<Column
				extraStyles={{
					// padding: styleguide.space.small,
					margin: `${styleguide.space.medium} 0`,
					width: '95%',

					borderRadius: styleguide.borderRadius.base,
					border: `1px solid ${styleguide.colors.separator}`
				}}
			>
				<iframe
					src={'https://iframe.orionapp.com.br/login'}
					style={{
						width: '100%',
						// minHeight: '400px',
						height: '80vh',
						borderRadius: styleguide.borderRadius.base,
						border: '0',
					}}
				/>
			</Column>

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}
