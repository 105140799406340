import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectIfNotLogged } from '../../global/Token'
import Button from '../../components/Button'
import ApiRequest, { Response } from '../../global/Api'
import Loading from '../../components/Loading'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import { Helmet } from 'react-helmet'

import { styledBaseBanner, styledBasePopupInstall, styledVideosModule } from './styles'
import ProgressBar from '../../components/ProgressBar'

import ImageBgTelegram from '../../assets/img/background-telegram.png'
import ImageBgOperations from '../../assets/img/background-operations.png'
import ImageBgOperations2 from '../../assets/img/background-operations2.png'

import IconExit from '../../assets/icons/exit.svg'
import IconAndroidWhite from '../../assets/icons/android-white.svg'
import IconAppleWhite from '../../assets/icons/apple-white.svg'

import IconAndroidGradient from '../../assets/icons/android-gradient.svg'
import IconAppleGradient from '../../assets/icons/apple-gradient.svg'

export default function Home(){

	const navigate = useNavigate()

	const [popup, setPopup] = useState({} as Response)
	const [popupDownload, setPopupDownload] = useState('' as '' | 'android' | 'ios')

	// useEffect(() => {



	// }, [])

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Column
				extraStyles={{
					margin: `${styleguide.space.medium} 0`
				}}
			>
				<Text
					textoption='subtitle'
				>
					Seja bem-vindo(a) ao
				</Text>
				<Text
					textcolor='mainColored'
					textoption='subtitle'
				>
					melhor sistema te investimentos do mundo
				</Text>
			</Column>

			{/* <Column
				extraStyles={{
					width: '100%',
					height: '250px',
					background: styleguide.colors.mainBackGradient,
					borderRadius: styleguide.borderRadius.base,
					marginBottom: styleguide.space.medium,

					display: 'flex',
					justifyContent: 'center',
				}}
			> */}
			{/* <script src="https://scripts.converteai.net/lib/js/smartplayer/v1/sdk.min.js" data-id="67535141b4388ad44a781c1f"></script> <div id="ifr_67535141b4388ad44a781c1f_wrapper" style={{ width: '100%' }}> <div style={{ position: 'relative' }} id="ifr_67535141b4388ad44a781c1f_aspect"> <iframe src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/67535141b4388ad44a781c1f/embed.html" id="ifr_67535141b4388ad44a781c1f" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe> </div> </div> */}
			{/* <div id="vid_67535141b4388ad44a781c1f" style={{ position: 'relative', width: '100%', padding: '56.25% 0 0' }}> <img id="thumb_67535141b4388ad44a781c1f" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/67535141b4388ad44a781c1f/thumbnail.jpg" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', objectFit: 'cover', display: 'block' }} alt="thumbnail" /> <div id="backdrop_67535141b4388ad44a781c1f" style={{ position: 'absolute', top: '0', height: '100%', width: '100%' }}></div> </div> */}
			{/* </Column> */}

			<div dangerouslySetInnerHTML={{ __html: '<div id="vid_67535141b4388ad44a781c1f" style="position: relative; width: 100%; padding: 56.25% 0 0;"> <img id="thumb_67535141b4388ad44a781c1f" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/67535141b4388ad44a781c1f/thumbnail.jpg" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail"> <div id="backdrop_67535141b4388ad44a781c1f" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%; "></div> </div>' }} />
			<Helmet>
				<script type="text/javascript" id="scr_67535141b4388ad44a781c1f"> var s=document.createElement("script"); s.src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/67535141b4388ad44a781c1f/player.js", s.async=!0,document.head.appendChild(s); </script>
			</Helmet>

			<Row
				extraStyles={{
					width: '100%',
				}}
			>
				<Column
					extraStyles={{
						...styledBaseBanner,
						flex: `1 0 calc(45% - (2 * ${styleguide.space.small}))`,

						backgroundImage: `url(${ImageBgTelegram})`,
						marginLeft: '0',
					}}
				>
					<Text
						textoption='subtitle'
						align='left'
					>
						Telegram
					</Text>
					<Text
						align='left'
					>
						acesse nosso canal no telegram e receba em primeira mão atualizações e notícias do mercado!
					</Text>
					<Button
						onclick={() => window.open('https://t.me/+0Dly0iv9aNE3OGIx')}
						extraStyles={{
							backgroundColor: 'white',
							color: 'black',

							width: '80%',
							marginRight: 'auto',
							marginTop: styleguide.space.small,
						}}
					>
						<b>Acessar</b>
					</Button>
				</Column>
				<Column
					extraStyles={{
						...styledBaseBanner,
						flex: `1 0 calc(55% - (2 * ${styleguide.space.small}))`,

						backgroundImage: `url(${ImageBgOperations2})`,
						marginRight: '0',
					}}
				>
					<Text
						textoption='subtitle'
						align='left'
					>
						Cadastre-se na corretora
					</Text>
					<Text
						align='left'
					>
						opere em uma corretora confiável e mantenha seu dinheiro reguro.
					</Text>
					<Button
						onclick={() => window.open('https://iframe.orionapp.com.br/login')}
						extraStyles={{
							backgroundColor: 'white',
							color: 'black',

							width: '80%',
							marginRight: 'auto',
							marginTop: styleguide.space.small,
						}}
					>
						<b>Fazer Cadastro</b>
					</Button>
				</Column>
			</Row>

			<Column
				extraStyles={{
					...styledBaseBanner,
					padding: styleguide.space.medium,

					backgroundImage: `url(${ImageBgOperations})`,
				}}
			>
				<Column
					extraStyles={{
						width: '70%',
						marginRight: 'auto',
					}}
				>
					<Text
						textoption='subtitle'
						align='left'
					>
						Entrar na sala de operações
					</Text>
					<Text
						align='left'
					>
						entre em nossa sala de operações e opere ao vivo com os maiores do mercado!
					</Text>
					<Button
						onclick={() => null}
						extraStyles={{
							backgroundColor: 'white',
							color: 'black',

							width: '100%',
							marginTop: styleguide.space.small,
						}}
					>
						<b>Entrar na sala</b>
					</Button>
				</Column>
			</Column>

			<Text
				textoption='subtitle'
				extraStyles={{
					marginTop: styleguide.space.medium,
				}}
			>
				Instale o nosso app
			</Text>

			<Row
				extraStyles={{
					justifyContent: 'space-evenly',
					marginTop: styleguide.space.small,
					marginBottom: styleguide.space.medium,
				}}
			>
				<Column
					extraStyles={{
						background: styleguide.colors.mainColoredBackGradient,
						width: '45%',

						borderRadius: '4px',
					}}
				>
					<Button
						onclick={() => setPopupDownload('ios')}
						extraStyles={styledBasePopupInstall}
					>
						<Row>
							<Image
								src={IconAppleGradient}
								extraStyles={{
									height: '14px',
									width: 'auto',
									minWidth: 'unset',

									marginRight: styleguide.space.small,
								}}
							/>
							<Text textoption='small'>
								IOS (Safari)
							</Text>
						</Row>
					</Button>
				</Column>
				<Column
					extraStyles={{
						background: styleguide.colors.mainColoredBackGradient,
						width: '45%',

						borderRadius: '4px',
					}}
				>
					<Button
						onclick={() => setPopupDownload('android')}
						extraStyles={styledBasePopupInstall}
					>
						<Row>
							<Image
								src={IconAndroidGradient}
								extraStyles={{
									height: '14px',
									width: 'auto',
									minWidth: 'unset',

									marginRight: styleguide.space.small,
								}}
							/>
							<Text textoption='small'>
							Android (Google Chrome)
							</Text>
						</Row>
					</Button>
				</Column>
			</Row>

			{
				popupDownload != '' && DownloadPopup(setPopupDownload, popupDownload)
			}

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}

function DownloadPopup(setPopupDownload: any, device: 'android' | 'ios'){

	const params = {
		android: {
			mainIcon: IconAndroidWhite,
			name: 'Android (Google Chrome)',
			steps: [
				{ text: 'Clique no icone de “Mais opções” no canto superior direito.' },
				{ text: 'Procure o botão “Tela de Inicio”' },
				{ text: 'Adicione o app à tela de inicio' },
			]
		},
		ios: {
			mainIcon: IconAppleWhite,
			name: 'IOS (Safari)',
			steps: [
				{ text: 'Clique no símbolo de compartilhar' },
				{ text: 'Procure o botão “Tela de Inicio”' },
				{ text: 'Adicione o app à tela de inicio' },
			]
		}
	}

	return <Column
		extraStyles={{
			position: 'fixed',
			top: '20vh',

			minHeight: '60vh',
			width: '350px',

			background: styleguide.colors.mainBackGradient,
			border: `2px solid ${styleguide.colors.mainColored}`,
			borderRadius: styleguide.borderRadius.base,
		}}
	>
		<Column
			extraStyles={{
				width: '80%'
			}}
		>
			<Image
				src={IconExit}
				onclick={() => setPopupDownload('')}
				extraStyles={{
					position: 'absolute',
					top: '20px',
					right: 'calc(10% - 10px)',

					minWidth: 'unset',
					width: '20px',
					height: 'auto',
				}}
			/>
			<Image
				src={params[device].mainIcon}
				extraStyles={{
					marginTop: styleguide.space.big,
					marginBottom: styleguide.space.small,

					height: '75px',
					width: 'auto',
					minWidth: 'unset',
				}}
			/>
			<Text
				textcolor='mainColored'
			>
				Instale o nosso app
			</Text>
			<Text

			>
				Siga o passo a passo abaixo para instalar nosso app em seu dispositivo!
			</Text>
			<Text
				textcolor='mainColored'
			>
				{ params[device].name }
			</Text>

			<Column
				extraStyles={{
					margin: `${styleguide.space.medium} 0`
				}}
			>
				<Row
					extraStyles={{
						flexWrap: 'nowrap',
						justifyContent: 'flex-start',
					}}
				>
					<Text
						extraStyles={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',

							width: '25px',
							height: '25px',

							background: 'black',
							border: `1px solid ${styleguide.colors.mainColored}`,
							borderRadius: `calc(${styleguide.borderRadius.small} / 2)`,

							marginRight: styleguide.space.small,
						}}
					>
						01
					</Text>
					<Text
						align='left'
					>
						{ params[device].steps[0].text }
					</Text>
				</Row>
				<hr
					style={{
						height: '1px',
						width: '100%',
						backgroundColor: styleguide.colors.soft,

						margin: styleguide.space.small,
					}}
				/>
				<Row
					extraStyles={{
						flexWrap: 'nowrap',
						justifyContent: 'flex-start',
					}}
				>
					<Text
						extraStyles={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',

							width: '25px',
							height: '25px',

							background: 'black',
							border: `1px solid ${styleguide.colors.mainColored}`,
							borderRadius: `calc(${styleguide.borderRadius.small} / 2)`,

							marginRight: styleguide.space.small,
						}}
					>
						02
					</Text>
					<Text
						align='left'
					>
						{ params[device].steps[1].text }
					</Text>
				</Row>
				<hr
					style={{
						height: '1px',
						width: '100%',
						backgroundColor: styleguide.colors.soft,

						margin: styleguide.space.small,
					}}
				/>
				<Row
					extraStyles={{
						flexWrap: 'nowrap',
						justifyContent: 'flex-start',
					}}
				>
					<Text
						extraStyles={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',

							width: '25px',
							height: '25px',

							background: 'black',
							border: `1px solid ${styleguide.colors.mainColored}`,
							borderRadius: `calc(${styleguide.borderRadius.small} / 2)`,

							marginRight: styleguide.space.small,
						}}
					>
						03
					</Text>
					<Text
						align='left'
					>
						{ params[device].steps[2].text }
					</Text>
				</Row>
				<hr
					style={{
						height: '1px',
						width: '100%',
						backgroundColor: styleguide.colors.soft,

						margin: styleguide.space.small,
					}}
				/>
			</Column>
		</Column>
	</Column>

}
