import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

export default styled.div`
	position: fixed;
	top: 0;
	left: 0;

	display: flex;
	justify-content: space-around;
	align-items: center;
	width: calc(100% + 4vw);
	background-color: black;
	padding: ${styleguide.space.small};

	border-bottom: 1px solid ${styleguide.colors.separator};

	z-index: 99;

	& img {
		width: 10%;
		min-width: 100px;
		height: auto;

		margin: 5px 0;
	}

	& > div {
		min-width: 200px;
		width: 40%;
		justify-content: space-around;
		align-items: center;
	}
`
