import React, { CSSProperties } from 'react'
import StyledText from './styles'

const textTags = {
	smaller: 'p',
	small: 'p',
	base: 'p',
	title: 'h1',
	subtitle: 'h2',
	note: 'span'
}

export default function Text({
	children,
	textoption = 'base',
	textcolor = 'base',
	align = 'center',
	extraStyles,
	onclick,
} : {
	children: React.ReactNode,
	textoption?: 'base' | 'title' | 'subtitle' | 'note' | 'small' | 'smaller',
	textcolor?: 'base' | 'mainColored' | 'secundaryColored' | 'error'
	align?: 'left' | 'center' | 'right'
	extraStyles?: CSSProperties
	onclick?: () => any
}){

	return (
		<StyledText
			textoption={textoption}
			textcolor={textcolor}
			style={{
				textAlign: align,
				width: align != 'center' ? '100%' : 'unset',
				cursor: onclick ? 'pointer' : 'unset',
				...extraStyles,
			}}
			onClick={onclick}
			as={textTags[textoption]}
		>
			{children}
		</StyledText>
	)

}
