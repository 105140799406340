import styled, { keyframes } from 'styled-components'
import { styleguide } from '../../global/Theme'

interface Popup { visible: boolean, msg: string, isError: boolean }

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`

const StyledPopup = styled.div<Popup>`
	padding: 10px 20px;
	margin: 12px 2.5vw 0 2.5vw;
	position: fixed;
	top: 0;
	right: 0;

	background-color: ${(props) => props.isError ? styleguide.backgroundColor.error : styleguide.backgroundColor.success};

	visibility: ${(props) => (props.msg != undefined ? 'visible' : 'hidden')};
	animation: ${(props) => (props.visible ? fadeIn : fadeOut)} 0.5s ease forwards;

	z-index: 1000;
`

export default StyledPopup
