import { InputHidden, InputText } from '../../components/Input'
import Column from '../../components/Column'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Link from '../../components/Link'
import { useEffect, useRef, useState } from 'react'
import { modelUserLogin } from './models'
import ApiRequest, { ResponseStructure } from '../../global/Api'
import Popup from '../../components/Popup'
import token, { setToken } from '../../global/Token'
import { useNavigate } from 'react-router-dom'
import { styleguide } from '../../global/Theme'
import Image from '../../components/Image'

import ImageLogoExtended from '../../assets/img/logo-extended-white.png'

export default function Login(){

	const navigate = useNavigate()

	const login_ref = useRef(modelUserLogin)
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [response, setResponse] = useState(ResponseStructure)

	// useEffect(() => {

	// 	if(token()) navigate('/')

	// }, [])

	async function handleLogin(){

		setButtonDisabled(true)

		localStorage.setItem('email', login_ref.current.email)

		// const response = await ApiRequest('/user/login', login_ref.current)

		// console.log({ response })
		// setResponse(response)

		// if(response.status != 200) setButtonDisabled(false)
		// else{

		// setToken(response.data.token)
		// setTimeout(() =>
		location.href = location.origin
		// , 1000)

		// }

	}

	return (
		<Column
			extraStyles={{
				height: 'calc(100vh - 100px)',
				margin: 'auto',
				width: '100%',
				justifyContent: 'center',
			}}
		>
			<Popup data={response} />

			<Column
				extraStyles={{
					background: styleguide.colors.mainBackGradient,
					height: '80vh',
					justifyContent: 'center',
					borderRadius: styleguide.borderRadius.base,
					border: `1px solid ${styleguide.colors.separator}`
				}}
			>
				<Column
					extraStyles={{
						marginBottom: styleguide.space.big
					}}
				>
					<Text
						textoption='subtitle'
					>
						Bem vindo(a) ao app
					</Text>
					<Image
						src={ImageLogoExtended}
						extraStyles={{
							margin: `${styleguide.space.medium} 0 ${styleguide.space.small} 0`,
							width: '50%',
						}}
					/>
					<Text>
						o melhor sistema de investimentos do mundo!
					</Text>
				</Column>

				<Column
					extraStyles={{
						width: '80%'
					}}
				>
					<Text
						textoption='title'
						extraStyles={{
							width: '100%',
							textAlign: 'left',
						}}
					>
						Login
					</Text>
					<Column extraStyles={{ width: '100%' }} >
						<InputText
							name='email'
							placeholder='Digite seu e-mail de compra '
							size='total'
							onchange={(ev) => login_ref.current.email = ev.target.value}
						/>
						<InputHidden
							name='password'
							placeholder='Digite sua senha'
							size='total'
							onchange={(ev) => login_ref.current.password = ev.target.value}
						/>
					</Column>
					<Button
						onclick={handleLogin}
						size='total'
						disabled={buttonDisabled}
						extraStyles={{
							background: styleguide.colors.mainColoredBackGradient,
						}}
					>
						<Text
							textoption='subtitle'
							extraStyles={{ color: 'black' }}
						>
							Fazer login
						</Text>
					</Button>
				</Column>

			</Column>
		</Column>
	)

}
