import React from 'react'
import HorizontalContainer from './styles'
import { CSSProperties } from 'styled-components'

export default function Column({
	children,
	leftalign,
	margin,
	onclick,
	extraStyles,
} : {
	children: React.ReactNode,
	leftalign?: boolean,
	margin?: 'big' | 'medium' | 'small'
	onclick?: () => any,
	extraStyles?: CSSProperties
}){

	return (
		<HorizontalContainer
			leftalign={leftalign}
			margin={margin}
			onClick={onclick}
			style={{
				...extraStyles,
				cursor: onclick ? 'pointer' : 'unset'
			}}
		>
			{children}
		</HorizontalContainer>
	)

}
