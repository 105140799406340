import styled from 'styled-components'
import { styleguide } from '../../global/Theme'
import { sizes } from '.'

interface styles {
	size: sizes
}

const styledSizes = {
	large: {
		width: '80vw',
		minWidth: '400px',
	},
	medium: {
		width: '40vw',
		minWidth: '200px',
	},
	small: {
		width: '20vw',
		minWidth: '100px',
	},
	smaller: {
		width: '10vw',
		minWidth: '50px',
	},
}

export default styled.video<styles>`
	width: ${({ size }) => styledSizes[size].width};
	min-width: ${({ size }) => styledSizes[size].minWidth};
`
