import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectIfNotLogged } from '../../global/Token'
import Button from '../../components/Button'
import ApiRequest, { Response } from '../../global/Api'
import Loading from '../../components/Loading'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import IconHomeBlack from '../../assets/icons/home-black.svg'
import IconOperationsBlack from '../../assets/icons/operations-black.svg'
import IconVideoBlack from '../../assets/icons/video-black.svg'

import ImageLogoAmazon from '../../assets/img/logo-amazon.svg'
import ImageLogoFacebook from '../../assets/img/logo-facebook.png'
import ImageLogoTesla from '../../assets/img/logo-tesla.svg'
import ImageLogoGoogle from '../../assets/img/logo-google.png'

import ImageGraphLine from '../../assets/img/graph-line.png'

export default function Portfolio(){

	const navigate = useNavigate()

	const [popup, setPopup] = useState({} as Response)
	const [profit, setProfit] = useState([{ name: '', profit: '' }])

	useEffect(() => {

		getCompaniesProfit()

	}, [])

	async function getCompaniesProfit(){

		const response = await ApiRequest('/random/data/get/companies/profit')

		if(response.status == 200) setProfit(
			response.data.map((el: any) => ({
				...el,
				profit:
					el.profit.slice(0, 3) +
					'.' +
					el.profit.slice(3, el.profit.length).replace('.', ',')
			}))
		)

	}

	const companiesList = [
		{
			name: 'Amazon',
			profit: profit[0]?.profit || '---',
			image: ImageLogoAmazon,
		},
		{
			name: 'Facebook',
			profit: profit[1]?.profit || '---',
			image: ImageLogoFacebook,
		},
		{
			name: 'Tesla',
			profit: profit[2]?.profit || '---',
			image: ImageLogoTesla,
		},
		{
			name: 'Google',
			profit: profit[3]?.profit || '---',
			image: ImageLogoGoogle,
		},
	]

	const companies = companiesList.map((companie) => <Row
		extraStyles={{
			width: '95%',
			justifyContent: 'space-evenly',
			alignItems: 'center',
			background: styleguide.colors.mainBack,
			padding: styleguide.space.small,
			marginBottom: styleguide.space.small,
			borderRadius: styleguide.borderRadius.base,
			border: `1px solid ${styleguide.colors.separator}`
		}}
	>
		<Column extraStyles={{
			width: 'auto',
			alignItems: 'flex-start',
		}}>
			<Image
				src={companie.image}
				extraStyles={{
					width: '20px',
					minWidth: 'unset',
				}}
			/>
			<Text>
				{ companie.name }
			</Text>
		</Column>
		<Image
			src={ImageGraphLine}
			extraStyles={{
				width: '40%',
				minWidth: 'unset',
			}}
		/>
		<Column extraStyles={{
			width: 'auto',
			alignItems: 'flex-start'
		}}>
			<Text
				textoption='subtitle'
			>
				Lucro do Dia
			</Text>
			<Text>
				R$ { companie.profit }
			</Text>
		</Column>
	</Row>)

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Text
				textoption='subtitle'
				extraStyles={{
					marginTop: styleguide.space.medium,
					color: styleguide.colors.soft,
				}}
			>
				Saldo total
			</Text>
			<Text
				textoption='title'
				extraStyles={{
					color: styleguide.colors.soft,
				}}
			>
				R$ <span style={{ color: 'white' }}>77.863</span> BRL
			</Text>

			<Row
				extraStyles={{
					width: '95%',
					justifyContent: 'space-around',
					alignItems: 'flex-end',
					margin: `${styleguide.space.medium} 0`
				}}
			>
				<Column
					extraStyles={{
						width: 'auto',
						minWidth: 'unset',
					}}
				>
					<Column
						onclick={() => navigate('/')}
						extraStyles={{
							width: 'auto',
							padding: styleguide.space.small,
							borderRadius: '100%',
							background: styleguide.colors.mainColored,
							marginBottom: styleguide.space.small,
						}}
					>
						<Image
							src={IconHomeBlack}
							size='smaller'
						/>
					</Column>
					<Text textcolor='mainColored'>
						Home
					</Text>
				</Column>
				<Column
					extraStyles={{
						width: 'auto',
						minWidth: 'unset',
					}}
				>
					<Column
						onclick={() => navigate('/operations')}
						extraStyles={{
							width: 'auto',
							padding: styleguide.space.medium,
							borderRadius: '100%',
							background: styleguide.colors.mainColored,
							marginBottom: styleguide.space.small,
						}}
					>
						<Image
							src={IconOperationsBlack}
							size='small'
						/>
					</Column>
					<Text textcolor='mainColored'>
						Operações
					</Text>
				</Column>
				<Column
					extraStyles={{
						width: 'auto',
						minWidth: 'unset',
					}}
				>
					<Column
						onclick={() => navigate('/academy')}
						extraStyles={{
							width: 'auto',
							padding: styleguide.space.small,
							borderRadius: '100%',
							background: styleguide.colors.mainColored,
							marginBottom: styleguide.space.small,
						}}
					>
						<Image
							src={IconVideoBlack}
							size='smaller'
						/>
					</Column>
					<Text textcolor='mainColored'>
						Aprender
					</Text>
				</Column>
			</Row>

			{ companies }

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}
