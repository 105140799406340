import React, { CSSProperties } from 'react'
import StyledButton from './styles'

export default function Button({
	children,
	onclick,
	disabled,
	size = 'base',
	margin,
	extraStyles,
} : {
	children: React.ReactNode
	onclick: () => any
	disabled?: boolean
	size?: 'base' | 'large' | 'total'
	margin?: 'big' | 'medium' | 'small'
	extraStyles?: CSSProperties
}){

	return (
		<StyledButton
			onClick={onclick}
			disabled={!!disabled}
			size={size}
			margin={margin}
			style={extraStyles}
		>
			{children}
		</StyledButton>
	)

}
