import React, { CSSProperties, useEffect, useState } from 'react'
import { StyledInputCheckbox, StyledInputFiles, StyledInputNumber, StyledInputRadio, StyledInputSelect, StyledInputText, StyledInputTextArea } from './styles'
import Row from '../Row'
import { styleguide } from '../../global/Theme'
import { SelectOptions } from './options'
import Column from '../Column'

import CloseEyeIcon from '../../assets/icons/OlhoFechado.png'
import OpenEyeIcon from '../../assets/icons/OlhoAberto.png'

type sizes = 'base' | 'large' | 'total'

interface RadioOption {
	text: string
	value: string
}

interface CheckboxOption {
	text: string
	value?: string
	checked?: boolean
}

export function InputText({
	name,
	onchange,
	children,
	placeholder,
	defaultValue,
	size = 'base',
	extraStyles,
} : {
	name: string
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any
	children?: React.ReactNode
	defaultValue?: string
	placeholder?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<Column
			leftalign
			margin='small'
			extraStyles={{
				width: styleguide.width.input[size],
			}}
		>
			{ children }
			<StyledInputText
				name={name}
				placeholder={placeholder}
				size={size}
				style={extraStyles}
				defaultValue={defaultValue || ''}
				onInput={onchange}
			/>
		</Column>
	)

}

export function InputNumber({
	name,
	onchange,
	children,
	placeholder,
	defaultValue,
	size = 'base',
	extraStyles,
} : {
	name: string
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any
	children?: React.ReactNode
	placeholder?: string
	defaultValue?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<Column
			leftalign
			margin='small'
			extraStyles={{
				width: styleguide.width.input[size],
			}}
		>
			{ children }
			<StyledInputNumber
				name={name}
				placeholder={placeholder}
				size={size}
				style={extraStyles}
				defaultValue={defaultValue || ''}
				onInput={onchange}
			/>
		</Column>
	)

}

export function InputHidden({
	name,
	onchange,
	children,
	placeholder,
	size,
	extraStyles,
} : {
	name: string
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any
	children?: React.ReactNode
	placeholder?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	const [isPasswordVisible, setPasswordVisible] = useState(false)

	const togglePasswordVisibility = () => setPasswordVisible(prevState => !prevState)

	return (
		<Column
			leftalign
			margin='small'
			extraStyles={{ position: 'relative', width: '100%' }}
		>
			{ children }
			<StyledInputText
				type={isPasswordVisible ? 'text' : 'password'}
				name={name}
				placeholder={placeholder}
				size={size}
				style={extraStyles}
				onInput={onchange}
			/>
			<img
				src={isPasswordVisible ? CloseEyeIcon : OpenEyeIcon}
				onClick={togglePasswordVisibility}
				style={{
					position: 'absolute',
					right: '10px',
					top: '22px',
					width: '20px',
					cursor: 'pointer',
				}}
			/>
		</Column>
	)

}

export function InputTextArea({
	name,
	onchange,
	children,
	placeholder,
	defaultValue,
	size = 'base',
	extraStyles,
} : {
	name: string
	onchange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => any,
	children?: React.ReactNode
	placeholder?: string
	defaultValue?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<Column
			leftalign
			margin='small'
			extraStyles={{ position: 'relative', width: '100%' }}
		>
			{ children }
			<StyledInputTextArea
				name={name}
				placeholder={placeholder}
				defaultValue={defaultValue}
				style={extraStyles}
				size={size}
				rows={8}
				onInput={onchange}
			/>
		</Column>
	)

}

export function InputSelect({
	name,
	options,
	onchange,
	children,
	defaultValue,
	size = 'base',
	extraStyles,
	// extraContainerStyles,
} : {
	name: string
	options: SelectOptions[]
	onchange: (ev: React.ChangeEvent<HTMLSelectElement>) => any
	children?: React.ReactNode
	defaultValue?: string
	size?: sizes
	extraStyles?: CSSProperties
	// extraContainerStyles?: CSSProperties
}){

	return (
		<Column
			leftalign
			margin='small'
			extraStyles={{
				width: styleguide.width.input[size],
				minWidth: styleguide.minWidth.input[size],
				// ...containerExtraStyles
			}}
			// extraStyles={extraContainerStyles}
		>
			{ children }
			<StyledInputSelect
				name={name}
				style={extraStyles}
				defaultValue={defaultValue}
				onChange={onchange}
			>
				{
					options.map((opt) => (
						<option value={opt.value} disabled={opt?.disabled}>
							{opt.text}
						</option>
					))
				}
			</StyledInputSelect>
		</Column>

	)

}

export function InputRadio({
	name,
	options,
	onchange,
	children,
	defaultValue,
	size = 'base',
	extraStyles,
} : {
	name: string
	options: RadioOption[]
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any,
	children?: React.ReactNode
	defaultValue?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	useEffect(() => {

		defaultValue != undefined
			? document.querySelector(`input[name="${name}"][value="${defaultValue}"]`)?.setAttribute('checked', 'true')
			: document.querySelector(`input[name="${name}"]`)?.setAttribute('checked', 'true')

	})

	return (
		<Column
			// leftalign
			margin='small'
			// extraStyles={extraContainerStyles}
			extraStyles={{
				width: styleguide.width.input['total'],
				minWidth: styleguide.minWidth.input['total'],
				fontWeight: '500',
			}}
		>
			{ children }
			<Row
				extraStyles={{
					marginTop: '4px',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{
					options.map((opt: RadioOption, i: number) => (
						<Row
							leftalign
							extraStyles={{
								width: styleguide.width.input[size],
								minWidth: styleguide.minWidth.input[size],
								fontWeight: '400',
								justifyContent: 'flex-start',
							}}
						>
							<StyledInputRadio
								// checked={i == 0}
								name={name}
								value={opt.value}
								style={extraStyles}
								onChange={onchange}
							/>
							{opt.text}
						</Row>
					))
				}
			</Row>
		</Column>
	)

}

export function InputCheckbox({
	name,
	options,
	onchange,
	children,
	size = 'base',
	extraStyles,
} : {
	name: string
	options: CheckboxOption[]
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any,
	children?: React.ReactNode
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<Column
			margin='small'
			extraStyles={{
				width: styleguide.width.input['total'],
				minWidth: styleguide.minWidth.input['total'],
				fontWeight: '500',
			}}
		>
			{ children }
			<Row
				extraStyles={{
					marginTop: '4px',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{
					options.map((opt: CheckboxOption, i: number) => (
						<Row
							leftalign
							extraStyles={{
								width: styleguide.width.input[size],
								minWidth: styleguide.minWidth.input[size],
								fontWeight: '400',
								justifyContent: 'flex-start',
							}}
						>
							<StyledInputCheckbox
								name={name}
								value={opt.value || opt.text}
								checked={opt.checked}
								style={extraStyles}
								onChange={onchange}
							/>
							{opt.text}
						</Row>
					))
				}
			</Row>
		</Column>
	)

}

export function InputFiles({
	name,
	onchange,
	children,
	size = 'base',
	extraStyles,
} : {
	name: string
	onchange: (ev: React.ChangeEvent<HTMLInputElement>) => any,
	children?: React.ReactNode
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<Column
			margin='small'
			extraStyles={{
				width: styleguide.width.input[size],
				minWidth: styleguide.minWidth.input[size],
				fontWeight: '500',
			}}
		>
			{children}
			<StyledInputFiles
				multiple
				name={name}
				style={extraStyles}
				onChange={onchange}
			/>
		</Column>
	)

}
