import { CSSProperties } from 'react'
import { styleguide } from '../../global/Theme'

export const styledVideosModule: CSSProperties = {
	width: '90vw',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	overflowX: 'auto',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	WebkitOverflowScrolling: 'touch'
}

export const styledBaseBanner: CSSProperties = {
	padding: `${styleguide.space.medium} ${styleguide.space.small}`,
	margin: styleguide.space.small,

	borderRadius: styleguide.borderRadius.base,

	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
}

export const styledBasePopupInstall: CSSProperties = {
	minWidth: 'unset',
	marginTop: '1px',
	width: 'calc(100% - 2px)',
	height: 'calc(100% - 2px)',

	background: styleguide.colors.mainBack,
	border: '0',
	borderRadius: '4px',

	justifyContent: 'center',
	alignItems: 'center'
}
