import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectIfNotLogged } from '../../global/Token'
import Button from '../../components/Button'
import ApiRequest, { Response } from '../../global/Api'
import Loading from '../../components/Loading'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import ImageExample from '../../assets/img/logo-amazon.svg'

export default function Academy(){

	const navigate = useNavigate()

	const [popup, setPopup] = useState({} as Response)

	// useEffect(() => {



	// }, [])

	const classesList = [
		{
			moduleName: 'Modulo 1',
		},
		{
			moduleName: 'Modulo 2',
		},
		{
			moduleName: 'Modulo 3',
		},
		{
			moduleName: 'Modulo 4',
		},
	]

	const classes = classesList.map((classe) => <Column
		extraStyles={{
			margin: `${styleguide.space.small} 0`
		}}
	>
		<Text
			textoption='subtitle'
			align='left'
			extraStyles={{
				margin: `${styleguide.space.small} 5%`
			}}
		>
			{ classe.moduleName }
		</Text>
		<Row
			extraStyles={{
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
				overflowX: 'auto',
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
				WebkitOverflowScrolling: 'touch'
			}}
		>
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
		</Row>
	</Column>)

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Column
				extraStyles={{
					width: '95%',
					marginTop: styleguide.space.big,
					marginBlock: styleguide.space.big,
				}}
			>
				<Text
					textoption='title'
				>
					<i>Academy</i>
				</Text>
				<Text>
					Aprenda a usar o app da melhor forma para copiar entradas dos <b>melhores analistas do mercado!</b>
				</Text>
			</Column>

			{ classes }

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}
