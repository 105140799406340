export default function NotFound(){

	return (
		<div>
			<h1>
			Página não encontrada
			</h1>
			<a href='/'>
			Voltar ao Início
			</a>
		</div>
	)

}
