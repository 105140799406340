import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

export default styled.div`
	position: fixed;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	width: 100%;
	background: ${styleguide.colors.mainBackGradient};

	border-top: ${() => `1px solid ${styleguide.colors.separator}`} ;

	border-top-right-radius: 40px;
	border-top-left-radius: 40px;

	z-index: 99;

	& div {
		padding-bottom: ${`calc(${styleguide.space.small} / 2)`};
		padding-top: ${styleguide.space.small};
	}

	& p {
		color: white;
	}

	& img {
		height: auto;
		width: 20px;

		min-height: unset;
		min-width: unset;

		margin-bottom: ${`calc(${styleguide.space.small} / 2)`};
	}
`
