import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from './global/Theme'
import AppRoutes from './Routes'

export default function App(){

	return (
		<BrowserRouter>
			<GlobalStyle />
			<AppRoutes />
		</BrowserRouter>
	)

}
