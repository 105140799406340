import StyledHeader from './styles'

import Row from '../Row'
import Text from '../Text'
import Button from '../Button'
import { setToken } from '../../global/Token'
import { useNavigate } from 'react-router-dom'
import Image from '../Image'

import ImageLogoExtended from '../../assets/img/logo-extended-white.png'
import { useEffect, useState } from 'react'

export default function Header(){

	const [user, setUser] = useState('')

	useEffect(() => {

		setUser(localStorage.getItem('email') || '')

	}, [])

	return (
		<StyledHeader>
			<Text>
				Olá { user }
			</Text>
			<Image
				src={ImageLogoExtended}
			/>
		</StyledHeader>
	)

}
