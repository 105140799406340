import styled from 'styled-components'
import { styleguide } from '../../global/Theme'
import { sizes } from '.'

interface styles {
	size: sizes
}

const styledSizes = {
	large: {
		width: '60vw',
		minWidth: '300px',
	},
	medium: {
		width: '30vw',
		minWidth: '150px',
	},
	small: {
		width: '30px',
		minWidth: '30px',
	},
	smaller: {
		width: '15px',
		minWidth: '15px',
	},
}

export default styled.img<styles>`
	width: ${({ size }) => styledSizes[size].width};
	min-width: ${({ size }) => styledSizes[size].minWidth};
`
