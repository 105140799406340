import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	leftalign?: boolean
	margin?: 'big' | 'medium' | 'small'
}

export default styled.div<styles>`
	display: flex;
	flex-direction: column;
	align-items: ${({leftalign}) => leftalign ? 'flex-start' : 'center'};

	margin: ${({ margin }) => `${styleguide.space[margin || 'none']} 0`};
`
