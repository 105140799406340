import styled, { css } from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	textoption: 'base' | 'title' | 'subtitle' | 'note'| 'small' | 'smaller',
	textcolor: 'base' | 'mainColored' | 'secundaryColored' | 'error'
}

const textStyles = {
	smaller: css`
		font-size: 6px;
		line-height: 1.5;
	`,
	small: css`
		font-size: 8px;
		line-height: 1.5;
	`,
	base: css`
	  	font-size: 12px;
	  	line-height: 1.5;
		font-weight: 400;
	`,
	subtitle: css`
	  	font-size: 20px;
	  	line-height: 1.5;
	  	font-weight: 500;
	`,
	title: css`
		font-size: 32px;
		line-height: 1.5;
		font-weight: 600;
	`,
	note: css`
		font-size: 12px;
		line-height: 1.5;
		color: ${styleguide.colors.soft};
		font-weight: 300;
	`,
}

export default styled.p<styles>`
	${({ textoption }) => textStyles[textoption]};
	color: ${({ textcolor }) => styleguide.colors[textcolor]};
`
