import { createGlobalStyle } from 'styled-components'

export const styleguide = {
	colors: {
		base: 'white',
		mainColored: '#1FFEC8', // Verde
		mainBackGradient: 'linear-gradient(153.26deg, #272727 1.69%, #121212 97.31%)', // Cinza
		mainColoredBackGradient: 'linear-gradient(90deg, #1FFEC8 0%, #5CED7B 100%)', // Verde
		mainBack: '#121212',

		separator: '#3E3E3E', // Cinza
		error: '#ff0000',

		secundaryColored: '#FFCD1D', // Amarelo
		neutralAction: 'blue',
		soft: '#9B9B9B', // Cinza
	},

	fontFamily: {
		main: 'Lato sans-serif',
		secundary: 'Lato sans-serif'
	},
	fontSize: {
		title: '24px',
		subtitle: '18px',
		paragraph: '12px',
		button: '16px',
	},
	fontWeight: {
		boldest: '800',
		bold: '600'
	},

	space: {
		big: '48px',
		medium: '24px',
		small: '12px',
		none: '0px'
	},

	backgroundColor: {
		main: 'black',
		container: '#333333',
		button: '#555555',
		editButton: '#007bff',
		error: '#ff0000',
		success: 'green'
	},

	width: {
		input: {
			base: '15vw',
			large: '30vw',
			total: '100%',
		},
		button: {
			base: '15vw',
			large: '30vw',
			total: '100%',
		},
	},
	minWidth: {
		input: {
			base: '150px',
			large: '300px',
			total: '100%',
		},
		button: {
			base: '150px',
			large: '300px',
			total: '100%',
		},
	},

	borderRadius: {
		base: '20px',

		big: '24px',
		medium: '10px',
		small: '8px',
	},

	hover: {
		button: {
			background: '#777777'
		},
		error: {
			background: '#dd0000'
		},
		editButton: {
			background: '#006adb'
		},
		success: {
			background: '#005a00'
		}
	}
}

export default createGlobalStyle`
	.elementor-element:has(#smartplayer) { width: 100%; }

    * {
        margin: 0;
        padding: 0;
		text-align: center;
		font-family: 'Lato', sans-serif;
		word-wrap: break-word;
		box-sizing: border-box;
    }

	#root {
		display: block;
		margin: 0 auto;
	}

    body {
		display: block;
		width: 98vw;
		max-width: 500px;
		margin: 65px auto;

        background: linear-gradient(162.84deg, #121212 0%, #060F08 100%);
        color: white;

		-ms-overflow-style: none;
    }

	body.margin0 {
		margin: 0;
		overflow-x: hidden;
	}

	div:not(#root) {
		width: 100%;
	}

	input, button, textarea {
		color: white;
	}

	table {
		border-collapse: collapse;
		border: 1px solid ${styleguide.colors.separator};
	}

	td {
		border: 1px solid ${styleguide.colors.separator};
		min-width: ${styleguide.minWidth.input.base};
		padding: ${styleguide.space.small};
	}

	::-webkit-scrollbar, ::-webkit-scrollbar-button {
		display: none;
	}

`
