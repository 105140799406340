import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	leftalign?: boolean
	size: 'base' | 'large' | 'total'
	margin?: 'big' | 'medium' | 'small'
}

export default styled.button<styles>`
	padding: 6px 12px;
	border: 1px solid black;
	border-radius: ${styleguide.borderRadius.small};

	background-color: ${styleguide.colors.mainColored};

	margin: ${({ margin }) => `${styleguide.space[margin || 'none']} 0`};

	width: ${({ size }) => styleguide.width.input[size]};
	min-width: ${({ size }) => styleguide.minWidth.input[size]};

	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`
