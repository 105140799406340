import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	leftalign?: boolean
}

export default styled.div<styles>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content:  ${({ leftalign }) => leftalign ? 'left' : 'center'};
`
