import token from '../Token'

export interface Response {
	status: number,
	msg: string,
	data?: any
}

export const ResponseStructure = {
	status: 200,
	msg: '',
	data: {}
} as Response

type sendToken = 'false' | 'admin' | 'user'

const defaultPath = process.env.REACT_APP_API_PATH

export default async function ApiRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	// console.log({ finalRoute }, { data })

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': sendToken == 'false' ? '' : token(sendToken)
		},
		body: JSON.stringify(data)
	})

	const response = await request.json() as Response

	return response

}

export async function ApiRequestVideo(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	// console.log({ finalRoute }, { data })

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			// 'Content-Type': 'application/json',
			'Authorization': sendToken == 'false' ? '' : token(sendToken)
		},
		body: data as BodyInit
		// JSON.stringify(data)
	})

	const response = await request.json() as Response

	return response

}

// export async function ApiRequestByBeacon(
// 	route: string,
// 	data = {},
// 	sendToken: sendToken = 'false',
// ){

// 	const apiPath = `${defaultPath}/api/v1`

// 	if(route.startsWith('/')) route = route.replace('/', '')

// 	const finalRoute = route.includes('internal/asset')
// 		? `${apiPath?.replace('/api/v1', '')}/${route}`
// 		: `${apiPath}/${route}`

// 	console.log({ finalRoute }, data)

// 	navigator.sendBeacon(finalRoute, JSON.stringify({
// 		...data,
// 		authorization: sendToken == 'false' ? '' : token(sendToken)
// 	}))

// }

export async function downloadRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': sendToken == 'false' ? '' : token(sendToken)
		},
		body: JSON.stringify(data)
	})

	const response = await request.blob() as Blob

	return response

}

export async function uploadRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Authorization': sendToken == 'false' ? '' : token(sendToken)
		},
		body: data as BodyInit
	})

	const response = await request.json()

	return response as Response

}

export async function externalRequest(
	route: string,
	params?: {
		method?: string,
		data?: any,
		headers?: any,
	}
){

	const request = await fetch(route, {
		method: params?.method || 'POST',
		headers: {
			'Content-Type': 'application/json',
			...params?.headers
		},
		body: JSON.stringify(params?.data)
	})

	const response = await request.json() // as Response

	return response

}
