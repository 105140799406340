import React, { CSSProperties } from 'react'
import VerticalContainer from './styles'

export default function Row({
	children,
	leftalign,
	extraStyles
} : {
	children: React.ReactNode,
	leftalign?: boolean,
	extraStyles?: CSSProperties
}){

	return (
		<VerticalContainer
			leftalign={leftalign}
			style={extraStyles}
		>
			{children}
		</VerticalContainer>
	)

}
