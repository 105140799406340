import { useRef, useState } from 'react'
import Button from '../../components/Button'
import Column from '../../components/Column'
import { InputRadio, InputSelect, InputText, InputTextArea } from '../../components/Input'
import Row from '../../components/Row'
import Text from '../../components/Text'

import Image from '../../components/Image'
import { OptionsLegalPerson } from '../../components/Input/options'
import { styleguide } from '../../global/Theme'
import Header from '../../components/Header'
import Video from '../../components/Video'

export default function Test(){

	const [documentType, setDocumentType] = useState('CPF')

	const contractOriginRef = useRef({
		documentType: 'CPF',
		document: ''
	})


	const contractDestinationRef = useRef({
		documentType: 'CPF',
		document: ''
	})

	const contractCostRef = useRef({
		value: 0,
	})

	const contract = {
		contractOriginRef,
		contractDestinationRef,
		contractCostRef,
	}

	function handleContractOriginRef(
		ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
	){

		const {
			value,
			name
		} = ev.target
		contract.contractOriginRef.current[name as keyof typeof contractOriginRef.current] = value

	}

	const qttDontComplete = 4 - 2
	const basewidth = 6 // vw

	return (
		<Column>
			<Header />

			<Video
				src={'http://localhost:8000/api/v1/video/get/1'}
				size='medium'
			/>

			{/* <Text
				textoption='title'
			>
				Página Teste
			</Text>

			<InputSelect
				name='documentType'
				options={[
					...OptionsLegalPerson,
					{ text: 'Outro', value: 'Ot', disabled: true }
				]}
				onchange={(ev) => setDocumentType(ev.currentTarget.value)}
			>
				<Text>Selecione o tipo de entidade: <Text textoption='note'>*opcional</Text></Text>
			</InputSelect>

			<InputText
				name='document'
				placeholder={documentType == 'CPF' ? '___.___.___-__' : '__.___.___/____-__'}
				onchange={handleContractOriginRef}
			>
				<Text>
					Digite o seu {documentType}
				</Text>
			</InputText>

			<Row>
				<InputText
					name='name'
					placeholder='Nome'
					onchange={handleContractOriginRef}
				/>
				<InputText
					name='nickname'
					placeholder='Sobrenome'
					onchange={handleContractOriginRef}
				/>
			</Row>

			<InputRadio
				name='testradio'
				options={[
					{ text: 'Teste A', value: 'A' },
					{ text: 'Teste B', value: 'B' },
					{ text: 'Teste C', value: 'C' },
					{ text: 'Teste D', value: 'D' },
				]}
				onchange={handleContractOriginRef}
			>
				Selecione sua opção de Teste
			</InputRadio>

			<Row>
				<div
					style={{
						position: 'absolute',
						marginTop: '20px',

						width: '0',
						height: '10px',
						backgroundColor: styleguide.colors.secundaryColored,

						paddingRight: `${basewidth}vw`,
						borderRight: `calc(${qttDontComplete * basewidth}vw + 80px) solid #D9D9D9`
						// transform: 'translateX(10px)'

					}}
				/>
				<Text
					textoption='subtitle'
					extraStyles={{
						padding: '10px 20px',
						borderRadius: '100%',
						backgroundColor: styleguide.colors.secundaryColored,
						margin: `0 ${basewidth/3}vw`,
						zIndex: 1,
					}}
				>
					1
				</Text>
				<Text
					textoption='subtitle'
					extraStyles={{
						padding: '10px 20px',
						borderRadius: '100%',
						backgroundColor: styleguide.colors.secundaryColored,
						margin: `0 ${basewidth/3}vw`,
						zIndex: 1,
					}}
				>
					2
				</Text>
				<Text
					textoption='subtitle'
					extraStyles={{
						padding: '10px 20px',
						borderRadius: '100%',
						backgroundColor: styleguide.colors.soft,
						margin: `0 ${basewidth/3}vw`,
						zIndex: 1,
					}}
				>
					3
				</Text>
				<Text
					textoption='subtitle'
					extraStyles={{
						padding: '10px 20px',
						borderRadius: '100%',
						backgroundColor: styleguide.colors.soft,
						margin: `0 ${basewidth/3}vw`,
						zIndex: 1,
					}}
				>
					4
				</Text>
			</Row>

			<InputTextArea
				name='description'
				placeholder='Descreva aqui...'
				onchange={handleContractOriginRef}
			>
				Descreva suas opções
			</InputTextArea>
			<InputTextArea
				name='description'
				placeholder='Descreva aqui...'
				onchange={handleContractOriginRef}
			>
				Descreva suas opções
			</InputTextArea>
			<InputTextArea
				name='description'
				placeholder='Descreva aqui...'
				onchange={handleContractOriginRef}
			>
				Descreva suas opções
			</InputTextArea>
			<InputTextArea
				name='description'
				placeholder='Descreva aqui...'
				onchange={handleContractOriginRef}
			>
				Descreva suas opções
			</InputTextArea>

			<Button
				onclick={() => 1}
			>
				Clique Aqui
			</Button> */}
		</Column>
	)

}
