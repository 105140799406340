import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import { Response } from '../../global/Api'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import ImageBackgroundOperations from '../../assets/img/background-operations.png'

import ImagePhotoElon from '../../assets/img/photo-elon.png'
import ImagePhotoMark from '../../assets/img/photo-mark.png'
import ImagePhotoWarren from '../../assets/img/photo-warren.png'
import ImagePhotoJeff from '../../assets/img/photo-jeff.png'
import ImagePhotoLarry from '../../assets/img/photo-larry.png'
import ImagePhotoPeter from '../../assets/img/photo-peter.png'

import ImageBgTesla from '../../assets/img/background-tesla.png'
import ImageBgFacebook from '../../assets/img/background-facebook.png'
import ImageBgBH from '../../assets/img/background-bh.png'
import ImageBgAmazon from '../../assets/img/background-amazon.png'
import ImageBgGoogle from '../../assets/img/background-google.png'
import ImageBgPaypal from '../../assets/img/background-paypal.png'

import socketio, { Socket } from 'socket.io-client'

export default function Operations(){

	const navigate = useNavigate()

	const [popup, setPopup] = useState({} as Response)
	const [socket, setSocket] = useState(null as null | Socket)
	const [socketData, setSocketData] = useState(null as any)

	useEffect(() => {

		setSocket(socketio(process.env.REACT_APP_SOCKET_URL || ''))

	}, [])

	useEffect(() => {

		if(socket != null){

			socket.on('ob_historical', (ev: any) => {

				console.log({ SocketData: ev })
				setSocketData(ev)

			})

		}

	}, [socket])

	const gameOptions = [
		{
			title: 'Tesla',
			description: 'Operadores do Elon Musk',
			url: ImagePhotoElon,
			backgroundUrl: ImageBgTesla,
			buttonNavigation: '/operations/tesla',
			data: socketData?.ETHUSD,
		},
		{
			title: 'Facebook',
			description: 'Operadores de Mark Zuckerberg',
			url: ImagePhotoMark,
			backgroundUrl: ImageBgFacebook,
			buttonNavigation: '/operations/facebook',
			data: socketData?.BTCUSD,
		},
		{
			title: 'Berkshire hattaway',
			description: 'Operadores de Warren Buffet',
			url: ImagePhotoWarren,
			backgroundUrl: ImageBgBH,
			buttonNavigation: '/operations/bh',
			data: socketData?.BTCUSD,
		},
		{
			title: 'Amazon',
			description: 'Operadores de Jeff Bezos',
			url: ImagePhotoJeff,
			backgroundUrl: ImageBgAmazon,
			buttonNavigation: '/operations/amazon',
			data: socketData?.BCHUSD,
		},
		{
			title: 'Google',
			description: 'Operadores de Larry Page',
			url: ImagePhotoLarry,
			backgroundUrl: ImageBgGoogle,
			buttonNavigation: '/operations/google',
			data: socketData?.LTCUSD,
		},
		{
			title: 'PayPal',
			description: 'Operadores de Peter Thiel',
			url: ImagePhotoPeter,
			backgroundUrl: ImageBgPaypal,
			buttonNavigation: '/operations/paypal',
			data: socketData?.XRPUSD,
		},
	]

	const games = gameOptions.map((game) => <Column
		extraStyles={{
			position: 'relative',

			width: '42.5%',
			margin: '2.5%',
			padding: '5%',

			background: styleguide.colors.mainBackGradient,

			borderRadius: styleguide.borderRadius.small,
		}}
	>
		<Image
			src={game.backgroundUrl}
			extraStyles={{
				position: 'absolute',
				height: 'auto',
				width: '95%',
				// maxWidth: '95%',

				opacity: '0.15',
				zIndex: '0'
			}}
		/>
		<Column
			extraStyles={{
				zIndex: '1',
			}}
		>
			<Row
				extraStyles={{
					width: '100%',
					marginBottom: styleguide.space.small,
				}}
			>
				<Column
					extraStyles={{
						width: '50px',
						height: '50px',
						minWidth: 'unset',
						borderRadius: styleguide.borderRadius.small,
					}}
				>
					<Image
						src={ game.url }
						extraStyles={{
							width: '100%',
							height: '100%',
							minWidth: 'unset',
							borderRadius: styleguide.borderRadius.small,
							objectFit: 'cover'
						}}
					/>
				</Column>
				<Column
					extraStyles={{
						width: 'calc(95% - 50px)',
						marginLeft: '5%',
						justifyContent: 'space-around',
					}}
				>
					<Text
						align='left'
					>
						{ game.title }
					</Text>
					<Text
						align='left'
						textoption='small'
					>
						{ game.description }
					</Text>
				</Column>
			</Row>
			<Text
				textoption='subtitle'
				extraStyles={{
					margin: `${styleguide.space.small} 0`
				}}
			>
				Resultados
			</Text>
			<Row
				extraStyles={{
					width: '100%',
					justifyContent: 'space-around',
				}}
			>
				<Row
					extraStyles={{
						width: 'auto'
					}}
				>
					<Text><span style={{ color: styleguide.colors.mainColored }}>{ game.data?.greens }</span> Wins</Text>
				</Row>
				<Row
					extraStyles={{
						width: 'auto'
					}}
				>
					<Text><span style={{ color: styleguide.colors.error }}>{ game.data?.reds }</span> Reds</Text>
				</Row>
			</Row>
			<Button
				onclick={() => navigate(game.buttonNavigation)}
				extraStyles={{
					margin: `${styleguide.space.small} 0`,
					background: styleguide.colors.mainColoredBackGradient,
					color: 'black',
					width: '80%',
				}}
			>
				<b>Acessar Sala</b>
			</Button>
			<Text>
				<span style={{ color: styleguide.colors.mainColored }}>{
					((
						parseInt(game.data?.greens)
						/ (parseInt(game.data?.greens) + parseInt(game.data?.reds))
						* 100
					) || '-- ').toString().split('.')[0]
				}%</span> de acerto
			</Text>
		</Column>
	</Column>)

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Text
				textoption='title'
				extraStyles={{
					margin: `${styleguide.space.small} 0`
				}}
			>
				Salas de Operações
			</Text>

			<Column
				extraStyles={{
					backgroundImage: `url(${ImageBackgroundOperations})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					width: '95%',
					padding: styleguide.space.medium,
					borderRadius: styleguide.borderRadius.base,
				}}
			>
				<Text
					textoption='subtitle'
					align='left'
					extraStyles={{ marginBottom: styleguide.space.small }}
				>
					Selecione a sala de operações
				</Text>
				<Text
					align='left'
				>
					Selecione abaixo a sala de operações que você deseja operar e opere ao vivo com os analistas das maiores empresas do mundo.
				</Text>
			</Column>

			<Row>
				{ games }
			</Row>

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}
