import React, { CSSProperties } from 'react'
import StyledImage from './styles'

export type sizes = 'large' | 'medium' | 'small' | 'smaller'

export default function Image({
	src,
	onclick,
	alt = 'Imagem',
	size = 'medium',
	extraStyles,
} : {
	src: string
	onclick?: () => any
	alt?: string
	size?: sizes
	extraStyles?: CSSProperties
}){

	return (
		<StyledImage
			src={src}
			alt={alt}
			onClick={() => onclick ? onclick() : null}
			size={size}
			style={{
				cursor: onclick ? 'pointer' : 'unset',
				...extraStyles
			}}
		>

		</StyledImage>
	)

}
