import { Route, Routes, useLocation } from 'react-router-dom'

import { verifyAndSetTimeUpdated } from './global/Token'
import { useEffect } from 'react'

import NotFound from './pages/NotFound'
import Home from './pages/Home'
import Test from './pages/Test'
import Login from './pages/Login'
import Operations from './pages/Operations'
import Portfolio from './pages/Portfolio'
import Academy from './pages/Academy'
import Operation from './pages/Operation'

export default function AppRoutes(){

	// const location = useLocation()

	// useEffect(() => { verifyAndSetTimeUpdated() }, [location])

	return (
		<Routes>

			<Route path='/login' element={<Login />} />

			<Route path='/' element={<Home />} />
			<Route path='/portfolio' element={<Portfolio />} />
			<Route path='/operations' element={<Operations />} />
			<Route path='/operations/:market' element={<Operation />} />
			<Route path='/academy' element={<Academy />} />

			<Route path='/test' element={<Test />} />

			<Route path='*' element={<NotFound />} />

		</Routes>
	)

}
