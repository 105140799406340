import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	leftalign?: boolean
	size?: 'base' | 'large' | 'total'
}

const baseStyles = {
	'box-sizing': 'border-box',

	'text-align': 'left',
	'height': '46px',

	'border-radius': styleguide.borderRadius.small,
	'border': `1px solid ${styleguide.colors.separator}`,
	'padding': '10px 8px',
	'background-color': styleguide.colors.mainBack,

	'margin': '4px 0',
}

export const StyledInputText = styled.input<styles>`
	${baseStyles};
	width: 100%;
`

export const StyledInputNumber = styled.input.attrs({ type: 'number' })<styles>`
	${baseStyles}
	width: 100%;
`

export const StyledInputTextArea = styled.textarea<styles>`
	${baseStyles}
	height: auto;
	width: 100%;
`

export const StyledInputSelect = styled.select<styles>`
	${baseStyles}
	width: 100%;

	& option {
		${baseStyles}
	}
`

export const StyledInputRadio = styled.input.attrs({ type: 'radio' })<styles>`
	accent-color: ${styleguide.colors.mainColored};
	margin-right: 4px;
`

export const StyledInputCheckbox = styled.input.attrs({ type: 'checkbox' })<styles>`
	accent-color: ${styleguide.colors.mainColored};
	margin-right: 4px;
`
export const StyledInputFiles = styled.input.attrs({ type: 'file' })<styles>`
	${baseStyles}
	width: 100%;
`
