import { NavigateFunction } from 'react-router-dom'
import ApiRequest from '../Api'

const jwtToken = {
	user: 'userToken',
	admin: 'adminToken',
}

const variableLastTime = 'jwtLastTime'

export default function token(role: 'user' | 'admin' = 'user'): string{

	const token = jwtToken[role]

	return localStorage.getItem(token) || ''

}

export function setToken(token: string, role: 'user' | 'admin' = 'user'){

	localStorage.setItem(jwtToken[role], token)

}

export async function decodeToken(role: 'user' | 'admin' = 'user'){

	const response = await ApiRequest(`user/auth/${token(role)}`)
	// console.log({ decodedData: response.data.timestamp })

	return response

}

export function setLastTimeUpdated(timestamp: number){

	localStorage.setItem(variableLastTime, timestamp.toString())

}

export function getLastTimeUpdated(){

	return parseInt(localStorage.getItem(variableLastTime) || '0')

}

export async function verifyAndSetTimeUpdated(){

	const timeWait = 1000 * 60 * 10 // 10 minutos

	const lastTime = getLastTimeUpdated()

	if(lastTime < Date.now() - timeWait){

		const response = await ApiRequest('/user/token/update', {}, 'user')

		if(response.status == 200){

			setLastTimeUpdated(Date.now())
			setToken(response.data.token)


		}

	}

}

export function redirectIfNotLogged(
	navigate: NavigateFunction
){

	if(!token()) navigate('/login')

}

// export async function updateToken(){

// 	const response = await ApiRequest('user/auth/update', {}, 'user')
// 	if(response.status == 400) return response

// 	console.log('Update Token.')

// 	localStorage.setItem('jwtUserData', response.data.token)

// 	response.data.haveAdminPermissions
// 		? localStorage.setItem('jwtAdminData', response.data.token)
// 		: localStorage.removeItem('jwtAdminData')

// }
